import { Link, NavLink } from "react-router-dom";

import { cx } from "utils";

import { SupportWidget, useSupportWidget } from "hooks/useSupportWidget";

import SpanLink from "components/SpanLink";
import StatefulLink from "components/StatefulLink";

import { useUser } from "routes/dataroutes/UserData";

/*
 * Example
 * Go to the [Billing Page|billing_page] to update your billing information.
 * or
 * Having troubles? [Contact support@scraperapi.com|contact_support_email].
 */
const tagRegEx = /\[([\w\s.!?@]+)\|(\w+)]/;

const defaultLinkStyle = "underline transition-colors cursor-pointer";

const getElementForTag = (tag: string, text: string, idx: number, supportWidget: SupportWidget, userEmail?: string, extraLinkStyle?: string, extraCallback?: () => void) => {

  switch (tag) {
    case "book_sales_call":
      return <StatefulLink
        to="/book-sales-call"
        onClick={ () => { extraCallback?.(); } }
        key={ tag + idx }
        addLocationToState
        className={ cx(extraLinkStyle, defaultLinkStyle) }
      >
        { text }
      </StatefulLink>;

    case "contact_support":
      return <SpanLink
        onClick={() => {
          supportWidget?.showSupportForm(userEmail);
          extraCallback?.();
        }}
        key={tag + idx}
        className={cx(extraLinkStyle, defaultLinkStyle)}
      >
        {text}
      </SpanLink>;

    case "contact_support_email":
      return <StatefulLink
        to="mailto:support@scraperapi.com"
        onClick={ () => { extraCallback?.() } }
        key={ tag + idx }
        className={ cx(extraLinkStyle, defaultLinkStyle) }
      >
        { text }
      </StatefulLink>;

    case "contact_growth_email":
      return <StatefulLink
        to="mailto:growth@scraperapi.com"
        onClick={ () => { extraCallback?.() } }
        key={ tag + idx }
        className={ cx(extraLinkStyle, defaultLinkStyle) }
      >
        { text }
      </StatefulLink>;

    case "contact_sales":
      return <SpanLink
        onClick={() => {
          supportWidget?.showSalesForm(userEmail);
          extraCallback?.();
        }}
        key={tag + idx}
        className={cx(extraLinkStyle, defaultLinkStyle)}
      >
        {text}
      </SpanLink>;

    case "billing_page":
      return <NavLink to="/billing" key={tag + idx} className={cx(extraLinkStyle, defaultLinkStyle)}>{text}</NavLink>;

    case "login_page":
      return <Link to="/login" className={ cx(extraLinkStyle, defaultLinkStyle) }>{ text }</Link>;

    case "signup_page":
      return <Link to="/signup" className={ cx(extraLinkStyle, defaultLinkStyle) }>{ text }</Link>;

    default:
      return <span>unsupported tag</span>;
  }
};


interface TagCallbacks {
  [index: string]: () => void;
}

interface ITaggedTextProps {
  message: string,
  className?: string,
  linkStyle?: string,
  tagCallbacks?: TagCallbacks
}

/**
 * Component which supports custom tags and can replace them with links.
 *
 * @param props
 */
export default function TaggedText(props: ITaggedTextProps) {

  const { message, className, linkStyle, tagCallbacks } = props;

  const supportWidget = useSupportWidget();
  const user = useUser();

  const messageParts = message.split(tagRegEx);
  const replacedParts = [];

  for (let i = 0; i < messageParts.length; i += 3) {
    // regular text before the tag
    replacedParts.push(messageParts[i]);
    if (messageParts[i+2]) {
      // found a tag
      replacedParts.push(getElementForTag(messageParts[i + 2], messageParts[i + 1], i, supportWidget, user?.email, linkStyle, tagCallbacks?.[messageParts[i + 2]]));
    }
  }

  return (
    <span className={className}>{replacedParts}</span>
  );

};
