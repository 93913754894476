import { ForwardedRef, forwardRef, Fragment, useCallback, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";

import { cx } from "utils";

import { Theme, Themes } from "styles/themes";

import { useSupportWidget } from "hooks/useSupportWidget";

import { useOnboardingTooltip } from "providers/OnboardingTooltipProvider";
import { BillingPeriodUnit } from "providers/UserProvider";

import Button from "components/Button";
import { useFeatureSwitch } from "components/FeatureSwitch";
import PriceTag from "components/PriceTag";
import Spinner from "components/Spinner";
import { PureTooltip } from "components/Tooltip";

import PlanFeatures from "./planFeatures";
import { PlanActions } from "./PlanActions";


export interface PricingPlanProps {
  name: string;
  price: number | undefined;
  originalPrice?: number;
  period?: number;
  periodUnit?: BillingPeriodUnit;
  isAnnual?: boolean;
  customPlanOverride?: CustomPlanOverrideType;
  planSlug: string;
  theme: Theme;
  bannerLabel?: string;
  buttonAction: PlanActions;
  buttonTheme?: Theme;
  buttonTooltip?: JSX.Element | React.ReactNode | string;
  buttonTooltipClickable?: boolean;

  userEmail?: string; // TODO this should go to a common 'contact sales' component
  planAutoRenewal: number | undefined;

  planIdx: number;
  planInActionIdx: number | undefined;
  setPlanInActionIdx: (planIdx: number) => void;
}

interface PricingPlanThemeType {
  bannerFill: string;
  bannerTextColor: string;
  borderColor: string;
}

const PricingPlanThemes: Themes<PricingPlanThemeType> = {
  error: {
    bannerFill: "bg-red-600",
    bannerTextColor: "text-white",
    borderColor: "border-red-600"
  },
  warning: {
    bannerFill: "bg-orange",
    bannerTextColor: "text-white",
    borderColor: "border-orange"
  },
  highlighted: {
    bannerFill: "bg-brandPrimary",
    bannerTextColor: "text-white",
    borderColor: "border-brandPrimary"
  },
  default: {
    bannerFill: "bg-lightGray",
    bannerTextColor: "text-white",
    borderColor: "border-borderColor"
  },
  disabled: {
    bannerFill: "bg-lightGray",
    bannerTextColor: "text-black",
    borderColor: "border-borderColor"
  }
};

export type CustomPlanOverrideType = {
  cf_api_auto_parsers?: string;
  cf_api_concurrency_limit?: number;
  cf_api_geotargeting?: string;
  cf_api_overage?: string;
  cf_api_premium_proxies?: string;
  cf_api_rendering?: string;
  cf_api_request_limit?: number;
};


function ComponentWithTooltip(
  {
    tooltipContent,
    tooltipClickable,
    className,
    children
  }: {
    tooltipContent: string | JSX.Element | React.ReactNode | undefined;
    tooltipClickable?: boolean;
    className?: string;
    children: JSX.Element | React.ReactNode;
  }
) {
  if (tooltipContent) {
    return (
      <PureTooltip content={ tooltipContent } className={ className } clickable={ tooltipClickable }>
        { children }
      </PureTooltip>
    );
  } else {
    return <div className={ className }>{ children }</div>;
  }
}


const PricingPlan = forwardRef(
  ({
     name,
     price,
     originalPrice,
     period = 1,
     periodUnit = "month",
     isAnnual,
     customPlanOverride,
     planSlug,
     theme,
     bannerLabel,
     buttonAction,
     buttonTheme,
     buttonTooltip,
     buttonTooltipClickable,
     userEmail,
     planAutoRenewal,
     planIdx,
     planInActionIdx,
     setPlanInActionIdx
   }: PricingPlanProps,
   ref: ForwardedRef<HTMLDivElement>
  ) => {
    const location = useLocation();
    const { activeStep, showNextAvailableStep } =
      useOnboardingTooltip();
    const supportWidget = useSupportWidget();

    const isCheckingBillingInfo = useMemo(() => {
        return planIdx === planInActionIdx;
      },
      [ planIdx, planInActionIdx ]
    );

    const setCheckingBillingInfo = useCallback(() => {
        setPlanInActionIdx(planIdx);
      },
      [ planIdx, setPlanInActionIdx ]
    );

    const features = PlanFeatures(planSlug, customPlanOverride);

    const cardTheme = PricingPlanThemes[theme] || PricingPlanThemes['default'];

    if (planInActionIdx !== undefined) {
      buttonTheme = "disabled";
    }

    const buttonProps = {
      theme: buttonTheme || "default",
      shouldPassBackgroundLocation: true,
      fullWidth: true,
      Icon: isCheckingBillingInfo ? Spinner : undefined,
      iconStyle: "absolute w-5 h-5 animate-spin"
    };

    const checkBillingAddressInNewDialog = useFeatureSwitch("REACT_APP_NEW_SUBSCRIPTION_DIALOGS_BILLING_ADDRESS_USERS");
    const checkPaymentSourcesInNewDialog = useFeatureSwitch("REACT_APP_NEW_SUBSCRIPTION_DIALOGS_PAYMENT_SOURCES_USERS");


    return (
      <div
        className="flex flex-col h-full w-full"
        ref={ ref }
      >
        <div
          className={ cx(!bannerLabel && "invisible", cardTheme.bannerFill, cardTheme.bannerTextColor, "text-center text-xs py-1") }>
          { bannerLabel || "placeholder" }
        </div>

        <div className={ cx(cardTheme.borderColor, "flex flex-col h-full px-5 py-6 border") }>
          <div className="text-sm uppercase text-brandDarkest font-medium">
            { name }
          </div>

          <div className={ "pt-1 pb-5 border-b border-borderColor" }>
            <PriceTag
              price={ price || "Custom" }
              originalPrice={ originalPrice }
              hasDiscount={ Boolean(price && originalPrice && (price !== originalPrice)) }
              period={ period }
              periodUnit={ periodUnit }
              isAnnual={ isAnnual || false }
            />
          </div>

          <ul className="flex flex-col pt-5 pb-6 space-y-4 text-xs">
            { features?.map((feature, idx) =>
              <li key={ idx } className="flex items-center">
                { feature }
              </li>
            ) }
          </ul>

          <div className="flex items-end mt-4 h-full">
            { buttonAction === "cancel_change" && (
              <Button
                { ...buttonProps }
                text="Cancel Change"
                href="/cancel-subscription-change"
              />
            ) }

            { buttonAction === "contact_sales" && (
              <Button
                text="Contact Sales"
                theme={ buttonTheme || "default" }
                onClick={ () => supportWidget?.showSalesForm(userEmail) }
                fullWidth
              />
            ) }

            { buttonAction === "downgrade" && (
              <Button
                { ...buttonProps }
                text="Downgrade"
                href={ `/downgrade-subscription/${ planSlug }` }
              />
            ) }

            { buttonAction === "downgrade_annual" && (
              <Button
                { ...buttonProps }
                text="Downgrade"
                href={ `/downgrade-annual-subscription/${ planSlug }` }
              />
            ) }

            { buttonAction === "downgrade_enterprise" && (
              <Button
                text="Downgrade"
                theme={ buttonTheme || "default" }
                shouldPassBackgroundLocation
                href="/cancel-enterprise"
                fullWidth
              />
            ) }

            { buttonAction === "pay_now" && (
              <Button
                text="Pay Now"
                theme={ buttonTheme || theme }
                shouldPassBackgroundLocation
                onClick={ (buttonTheme === "disabled") ? undefined : setCheckingBillingInfo }
                href="/pay-now"
                fullWidth
                Icon={ isCheckingBillingInfo ? Spinner : undefined }
                iconStyle="absolute w-5 h-5 animate-spin"
              />
            ) }

            { buttonAction === "renew" && (
              <ComponentWithTooltip tooltipContent={ buttonTooltip } tooltipClickable={ buttonTooltipClickable } className="relative w-full flex items-stretch flex-shrink-0 justify-center">
                <Button
                  text="Renew Now"
                  onClick={ (buttonTheme === "disabled") ? undefined : setCheckingBillingInfo }
                  href={ `/confirm-renewal/${ planSlug }` }
                  className="py-1.5 flex"
                  fullWidth
                  theme={ buttonTheme || theme }
                  shouldPassBackgroundLocation
                  Icon={ isCheckingBillingInfo ? Spinner : undefined }
                  iconStyle="absolute w-5 h-5 animate-spin"
                />
                { (buttonTheme === "disabled") && (
                  <div className="border-t border-b border-r border-gray opacity-30 px-3 items-center flex">
                    <ChevronDownIcon
                      className="w-5 h-5 text-gray"
                      aria-hidden="true"
                    />
                  </div>
                ) }
                { (buttonTheme !== "disabled") && (
                  <Menu as="div"
                        className="border-t border-b border-r bg-brandPrimary-600 hover:bg-brandPrimary-700 text-white shadow-sm">
                    <Menu.Button className="flex items-center justify-center h-full px-3">
                      <ChevronDownIcon
                        className="w-5 h-5 text-white group-hover:text-white"
                        aria-hidden="true"
                      />
                    </Menu.Button>
                    <Transition
                      as={ Fragment }
                      show={ activeStep === "auto_renew" || undefined }
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items
                        className="dyna-layout absolute right-0 mt-2 origin-top-right bg-white divide-y divide-gray-100 shadow-lg w-72 ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div
                          className="z-50 px-1 py-1">
                          <Menu.Item>
                            <>
                              <Link
                                to={ `/confirm-renewal/${ planSlug }` }
                                onClick={ setCheckingBillingInfo }
                                state={ { backgroundLocation: location } }
                                className="group !capitalize flex w-full items-center px-2 py-2 text-sm hover:bg-brandPrimary/50 hover:text-white text-gray-900"
                              >
                                Renew now
                              </Link>
                              { activeStep === "auto_renew" &&
                                buttonAction === "renew" && (
                                  <div
                                    className="absolute left-0 capitalize -translate-y-[150%] md:translate-y-0 md:-translate-x-[105%] right-0 z-50 p-3 text-black bg-white border shadow-xl  border-brandPrimary"
                                  >
                                    You can set up auto-renewal here
                                    <div className="flex justify-end mt-2 gap-x-3">
                                      <button
                                        className="underline hover:text-brandPrimary-800 text-brandPrimary"
                                        onClick={ showNextAvailableStep }
                                      >
                                        Ok
                                      </button>
                                    </div>
                                  </div>
                                ) }
                            </>
                          </Menu.Item>
                          <Menu.Item>
                            <Link
                              to="/auto-renewal/95"
                              onClick={ setCheckingBillingInfo }
                              state={ { backgroundLocation: location } }
                              className={ cx(
                                (planAutoRenewal === 95) &&
                                "!bg-brandPrimary !text-white",
                                "group !capitalize flex w-full items-center px-2 py-2 text-sm hover:bg-brandPrimary/50 hover:text-white text-gray-900"
                              ) }
                            >
                              Renew automatically at 95% usage
                            </Link>
                          </Menu.Item>
                          <Menu.Item>
                            <Link
                              to="/auto-renewal/100"
                              onClick={ setCheckingBillingInfo }
                              state={ { backgroundLocation: location } }
                              className={ cx(
                                (planAutoRenewal === 100) &&
                                "!bg-brandPrimary !text-white",
                                "group !capitalize flex w-full items-center px-2 py-2 text-sm hover:bg-brandPrimary/50 hover:text-white text-gray-900"
                              ) }
                            >
                              Renew automatically at 100% usage
                            </Link>
                          </Menu.Item>
                          <Menu.Item>
                            <Link
                              to="/auto-renewal/clear"
                              onClick={ setCheckingBillingInfo }
                              state={ { backgroundLocation: location } }
                              className={ cx(
                                (planAutoRenewal === null) &&
                                "!bg-brandPrimary !text-white",
                                "group !capitalize flex w-full items-center px-2 py-2 text-sm hover:bg-brandPrimary/50 hover:text-white text-gray-900"
                              ) }
                            >
                              Standard monthly renewal
                            </Link>
                          </Menu.Item>
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                ) }
              </ComponentWithTooltip>
            ) }

            { buttonAction === "upgrade" && (
              <Button
                text="Upgrade"
                theme={ buttonTheme || "default" }
                shouldPassBackgroundLocation={ !(checkBillingAddressInNewDialog || checkPaymentSourcesInNewDialog) }
                onClick={ ((buttonTheme === "disabled") || (checkBillingAddressInNewDialog && checkPaymentSourcesInNewDialog)) ? undefined : setCheckingBillingInfo }
                href={ (checkBillingAddressInNewDialog || checkPaymentSourcesInNewDialog) ? `/billing/plans/upgrade/${ planSlug }` : `/upgrade-subscription/${planSlug}` }
                fullWidth
                Icon={ isCheckingBillingInfo ? Spinner : undefined }
                iconStyle="absolute w-5 h-5 animate-spin"
              />
            ) }

            { buttonAction === "upgrade_annual" && (
              <Button
                { ...buttonProps }
                text="Upgrade"
                href={ `/upgrade-annual-subscription/${ planSlug }` }
              />
            ) }

          </div>
        </div>
      </div>
    );
  });

export default PricingPlan;
