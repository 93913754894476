import axios from "axios";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import { useLocation, useRevalidator } from "react-router-dom";

import scraperApi from "api";

import { useCustomerIO } from "providers/CustomerIOProvider";
import { useUser } from "routes/dataroutes/UserData";


export type UserType = {
  apiCallLimit?: number;
  apiKey: string;
  canUseAllCoupons?: boolean;
  canUseCoupons: boolean;
  chargebeeCustomerId: string;
  concurrentRequestLimit?: number;
  concurrentRequests: number;
  email: string;
  firstName?: string;
  lastName?: string;
  failedRequests: number;
  firstLogin: boolean;
  hasPassword: boolean;
  id: number;
  planAutoRenewal?: number;
  planName?: string;
  planSlug?: string;
  planTypeId?: string;
  requestCount: number;
  hideOnboarding: boolean;
  hasPaymentIssues: boolean;
  isBlocked: boolean;
  blockingCode?: string;
  isBannedFromFree: boolean;
  signedUpAt?: string;
  apiKeyExpirationDays?: number;
  canHaveAffiliatePlan?: boolean;
  isRenewalAllowed?: boolean;
};

export type BillingPeriodUnit = "month" | "year";

export type CouponDetails = {
  coupon_code: string;
  coupon_type: "firstpromoter" | "chargebee";
  added_at?: Date;
  chargebee_coupon_id: string;
  coupon_name?: string;
  discount?: string;
};

export type SubscriptionType = {
  activated_at: number;
  auto_renewal_attempt: boolean;
  base_currency_code: string;
  billing_period: number;
  billing_period_unit: BillingPeriodUnit;
  cf_api_auto_parsers: string;
  cf_api_concurrency_limit: number;
  cf_api_geotargeting: string;
  cf_api_premium_proxies: string;
  cf_api_rendering: string;
  cf_api_request_limit: number;
  cf_api_overage: "Enabled";

  channel: string;
  created_at: number;
  currency_code: string;
  current_term_end: number;
  current_term_start: number;
  customer_id: string;
  deleted: boolean;
  due_invoices_count: number;
  exchange_rate: number;
  couponDetails?: CouponDetails[];
  has_scheduled_changes: boolean;
  id: string;
  last_renewal_attempt: Date | null;
  mrr: number;
  next_billing_at: number;
  next_renewal_at: number;
  object: string;
  override_relationship: boolean;
  plan_amount: number;
  plan_free_quantity: number;
  plan_id: string;
  plan_name: string;
  plan_quantity: number;
  plan_unit_price: number;
  discount_price?: number;
  resource_version: number;
  scheduledSubscription: UserContextType["subscription"];
  started_at: number;
  status: string;
  updated_at: number;
  name?: string;
};

export type Plan = {
  id: string;
  name: string;
  price?: number;
  discount_price?: number;
  currency_code?: string;  // TODO we should use it in the fmtCurrency function
  period?: number;
  period_unit?: BillingPeriodUnit;

  cf_api_request_limit?: number;
  cf_api_concurrency_limit?: number;
  cf_api_geotargeting?: string;
};

type AppStorageType = {
  hideOnboarding: boolean;
};

type BaseUserType = {
  refresh: (controller?: AbortController) => Promise<void>;
  inProgress: boolean;
  appStorage: AppStorageType;
};

export type UserContextType = BaseUserType & {
  subscription?: SubscriptionType;
  plans?: Plan[];
};

// This type can be used when we can guarantee that the user is logged in.
// For example in routes that won't render without a user, subscription or plans
// Usage: Cast the useUser() hook with this type, and you should be able to assume
// that the properties are not null.
export type RequiredUserContextType = BaseUserType & {
  subscription?: SubscriptionType;
  plans: Plan[];
};

let UserContext = createContext<UserContextType>(null!);
let timerHandle: NodeJS.Timer;

export default function UserProvider({ children }: { children: ReactNode }) {
  const user = useUser();
  const revalidator = useRevalidator();

  let [subscription, setSubscription] = useState<any>(null);
  let [appStorage, setAppStorage] = useState<any>(null);
  let [plans, setPlans] = useState<any>(null);
  const [inProgress, setInProgress] = useState(true);
  const customerIO = useCustomerIO();

  const location = useLocation();
  const hasBootedandIsLoggedIn = useMemo(
    () => !!user && !!subscription && !!plans,
    [plans, subscription, user]
  );
  const fetchUserData = useCallback(async (controller?: AbortController) => {
    try {
      setAppStorage({
        hideOnboarding: !!localStorage?.getItem("hideOnboarding")
      });

      const subscription = await scraperApi.subscription.details({
        ...(controller && { signal: controller.signal })
      });

      if (subscription) {
        setSubscription(subscription.activeSubscription);
        setPlans(subscription.plans);
      }
    } catch (err) {
      if (!axios.isCancel(err)) {
        console.error(err);
      }
    }
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    (async () => {
      try {
        setInProgress(true);
        await fetchUserData(controller);
        setInProgress(false);
      } catch (err) {
        // If it's aborted it's from the unmounting of the component
        if (axios.isCancel(err)) {
          setInProgress(true);
        } else {
          setInProgress(false);
        }
      }
    })();

    return () => {
      controller.abort();
    };
  }, [fetchUserData]);

  // if last_renewal_attempt is not null then refresh every 5 seconds
  // or
  // if the subscription in the database is different from the subscription in chargebee then refresh every 5 seconds
  useEffect(() => {
    if ((!subscription?.last_renewal_attempt) &&
      (user?.planSlug === subscription?.plan_id)) {
      if (timerHandle) clearInterval(timerHandle);
      return;
    }

    timerHandle = setInterval(() => {
      revalidator.revalidate();
      fetchUserData();
    }, 5000);

    return () => clearInterval(timerHandle);
  }, [ fetchUserData, subscription, user, revalidator ]);

  useEffect(() => {
    // Everytime location changes, we want to refetch the user data
    // We only do it after we "booted" the application, to avoid duplicate requests
    if (hasBootedandIsLoggedIn) {
      fetchUserData();
    }
  }, [fetchUserData, hasBootedandIsLoggedIn, location.pathname]);

  useEffect(() => {
    if (user?.id) {
      customerIO?.identify?.(user.id.toString(10));
    }
  }, [ customerIO, user?.id ]);

  let value = {
    inProgress,
    subscription,
    appStorage,
    plans,
    refresh: fetchUserData
  };
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}

export function useUserProvider() {
  return useContext(UserContext);
}
