import { useActionData } from "react-router-dom";

import TaggedText from "components/TaggedText";

import IRouterActionError from "routes/dataroutes/IRouterActionError";


export default function useFormattedActionError(): string | JSX.Element | undefined {
  const error = useActionData() as IRouterActionError;

  if (error?.taggedMessage) {
    return <TaggedText message={ error.taggedMessage.message } linkStyle={ error.taggedMessage.linkStyle }/>;
  } else {
    return error?.message;
  }

};
